import LoadGptScript  from './gpt/LoadGptScript.js';
import LogTools       from './LogTools.js';
import ShowAd         from './gpt/ShowAd.js'
import SlotDatas    from './SlotDatas';

const gptSlotDivIdAttr = 'gpt-slot-div-id';           // Predetermined <div> ID must be defined in header
const gptSlotDivIdBaseAttr = 'gpt-slot-div-id-base';  // Explicit <div> ID must be defined in header
const gptSlotConfigIdAttr = 'gpt-slot-config-id';     // Slot Config ID to use
const gptSlotReadyAttr = 'slot-ready';                // Signifies a slot element has been processed
const gptSlotDisplayedAttr = 'slot-display';                // Signifies a slot element has been processed

const gptRefreshTimerKey = 'refreshTimer';        // Leverage targeting values to refresh ads

export { gptSlotDivIdAttr, gptSlotDivIdBaseAttr, gptSlotConfigIdAttr, gptSlotReadyAttr };

const htmlUsage = `
------------------------------------------------------------------------------------------
fdn-gpt.js configuration and options example - Define before fdn-gpt.js include
------------------------------------------------------------------------------------------
  <script>
  window.Foundation = window.Foundation || {};
  window.Foundation.Gpt = window.Foundation.Gpt || {};
  window.Foundation.Gpt.Options = window.Foundation.Gpt.Options || {};

  // Enable/Disable debugging output to the console [true|false]
  window.Foundation.Gpt.Options.debugging = false;

  // Enable/Disable placeholder ads [true|false]
  window.Foundation.Gpt.Options.showPlaceholders = false;

  // Breakpoints at which to re-request ads upon window resize or screen rotation
  window.Foundation.Gpt.Options.layoutBreakPoints =['1200', '768', '510'];

  // Page Level targeting key value pairs to supply for GPT tag
  window.Foundation.Gpt.Options.pageLeveTargeting = [{
    key: "template", value: ["Home"]
  },{
    key: "media", value: ["screen"]
  }];

  // Only use for ads guaranteed to be in the page
  // Dyanmic slots get '-1', '-2', etc. appended and may be added for definition in header
  window.Foundation.Gpt.Options.defineInHeaderSlotIdList = [
    'SiteSkin', 'FixedFooter', 'Rectangle-1', 'Rectangle-2', 'Rectangle-3',
  ];

  // Slot Configuration Data example
  window.Foundation.Gpt.Options.slotPresetData || [{
    type: "outofpage",
    id: "SiteSkin",
    adUnit: "/1234/siteskin_ad_unit"
  },{
    type: "topanchor",
    id: "TopAnchor",
    adUnit: "/1234/leaderboard_ad_unit"
  },{
    type: "bottomanchor",
    id: "BottomAnchor",
    adUnit: "/1234/leaderboard_ad_unit"
  },{
    type: "display",
    id: "Leaderboard",
    adUnit: "/1234/leaderboard_ad_unit",
    sizes: [[970,90], [728,90], [320, 100], [320, 50], [300, 100], [300, 50]],
    sizeMap: [
      { minSize: [1040, 0], sizes: [[970,90], [728, 90]] },
      { minSize: [779, 0], sizes: [[728, 90]] },
      { minSize: [0, 0], sizes: [[320, 100], [320, 50], [300, 100], [300, 50]] }
    ]
    collapseEmptyDiv: true,
  },{
    type: "display",
    id: "FixedFooter",
    adUnit: "/1234/leaderboard_ad_unit",
    sizes: [[970, 90], [728,90], [320, 100], [320, 50], [300, 100], [300, 50]],
    sizeMap: [
      { minSize: [1040, 0], sizes: [[970, 90], [728, 90]] },
      { minSize: [779, 0], sizes: [728, 90] },
      { minSize: [0, 0], sizes: [[320, 100], [320, 50], [300, 100], [300, 50]] }
    ],
    targeting: [
      { key: "position",  value: ["sticky"] },
      { key: "refreshTimer",  value: ["30", "2"] }
    ]
  },{
    type: "display",
    id: "Rectangle",
    adUnit: "/1234/rectangle_ad_unit",
    sizes: [300, 250],
    sizeMap: [
      { minSize: [300, 0], sizes: [300, 250] },
      { minSize: [0, 0], sizes: [] }
    ],
    collapseEmptyDiv: true,
    targeting: [
      { key: "position",  value: ["ATF"] },
    ]
  }];

  // Optional refresh ads window resize across layoutBreakPoints thresholds
  window.addEventListener('foundation:ad:resize', () => { 
    Foundation.Gpt.RefreshAll();
  });
  </script>
  <script src="/path/to/fdn-gpt.js"></script>

------------------------------------------------------------------------------------------
Ad Element HTML Usage
------------------------------------------------------------------------------------------

  SLOTS DEFINED IN HEADER - Use for slots guaranteed to be in the page before page load.
  ----------------------------------------------------------------------------------------

  Predetermined HTML:

  <div ${gptSlotDivIdAttr}="Rectangle-taco" hidden></div>

  - IDs must be in the Foundation.Gpt.Options.defineInHeaderSlotIdList array.

  - ID base (everything before the first \`-\`) should be the ID of the defined slot config.
    Foundation.Gpt.Options.defineInHeaderSlotIdList array before the fdn-gpt.js code executes.

  - gpt ad slot is displayed after found in the DOM.


  Dynamic HTML:

  <div ${gptSlotDivIdBaseAttr}="Rectangle" ${gptSlotConfigIdAttr}="Rectangle" hidden></div>

  - Possible generated ${gptSlotDivIdAttr} values must be in the 
    Foundation.Gpt.Options.defineInHeaderSlotIdList array before the fdn-gpt.js code executes.

  - ${gptSlotDivIdBaseAttr} used to generate ${gptSlotDivIdAttr} with a suffix after found in HTML.
    Ex: Rectangle-1, Rectangle-2, Rectangle-3 etc.

  - gpt ad slot is displayed after ${gptSlotDivIdAttr} is generated and found in the DOM.


  SLOTS DEFINED INLINE - Use for lazy loading and slots injected after page load.
  ----------------------------------------------------------------------------------------

  Inline HTML:

  <div ${gptSlotConfigIdAttr}="Rectangle" hidden></div>

  - ${gptSlotConfigIdAttr} used to generate ${gptSlotDivIdAttr} with a suffix after found in HTML.
    Ex: Rectangle-inline-1, Rectangle-inline-2, Rectangle-inline-3 etc.

  - gpt ad slot is defined and displayed after ${gptSlotDivIdAttr} is generated and found in the DOM

------------------------------------------------------------------------------------------
`;


// Base Object
window.Foundation = window.Foundation || {};
window.Foundation.Gpt = window.Foundation.Gpt || {};
window.Foundation.Gpt.Options = window.Foundation.Gpt.Options || {};

// Required Options - Define in page before including this script
if (typeof window.Foundation.Gpt.Options.defineInHeaderSlotIdList === 'undefined') {
  LogTools.warn(`Required: window.Foundation.Gpt.Options.defineInHeaderSlotIdList not defined. Define in page before loading GPT script.`);
  return;
}


// Support for legacy window.Foundation.Gpt.Options.slotConfigData
if (typeof window.Foundation.Gpt.Options.slotPresetData === 'undefined') {
  LogTools.warn(`Required: window.Foundation.Gpt.Options.slotPresetData not defined. Define in page before loading GPT script.`);
  if (typeof window.Foundation.Gpt.Options.slotConfigData === 'undefined') {
    return;
  }
  else {
    const slotDataPresets = new SlotDatas({ datas: window.Foundation.Gpt.Options.slotConfigData });
    LogTools.warn(`Deprecated: window.Foundation.Gpt.Options.slotConfigData found. Rename to window.Foundation.Gpt.Options.slotPresetData.`);
  }
}

const slotDataPresets = new SlotDatas({ datas: window.Foundation.Gpt.Options.slotPresetData || window.Foundation.Gpt.Options.slotConfigData });
window.Foundation.Gpt.SlotDataPresets = slotDataPresets;


// Recommended Options - Define in page before including this script
if (typeof window.Foundation.Gpt.Options.pageLeveTargeting === 'undefined') {
  LogTools.warn(`window.Foundation.Gpt.Options.pageLeveTargeting not defined.`)
}
else if( window.Foundation.Gpt.Options.pageLeveTargeting.length === 0) {
  LogTools.warn(`window.Foundation.Gpt.Options.pageLeveTargeting does not contain any items.`)
}
window.Foundation.Gpt.Options.pageLeveTargeting = window.Foundation.Gpt.Options.pageLeveTargeting || [];


// Default Options - Define in page before including this script for custom options
window.Foundation.Gpt.Options.debugging = window.Foundation.Gpt.Options.debugging || false;
window.Foundation.Gpt.Options.showPlaceholders = window.Foundation.Gpt.Options.showPlaceholders || false;
window.Foundation.Gpt.Options.layoutBreakPoints = window.Foundation.Gpt.Options.layoutBreakPoints || [998, 768, 600, 400];

if (typeof window.Foundation.Gpt.Options.loadGptScript === 'boolean') {
  window.Foundation.Gpt.Options.loadGptScript = window.Foundation.Gpt.Options.loadGptScript;
}
else {
  window.Foundation.Gpt.Options.loadGptScript = true;
}

if (window.Foundation.Gpt.Options.loadGptScript) {
  LoadGptScript();
}

if (window.Foundation.Gpt.Options.debugging) {
  LogTools.enable();
  LogTools.info(htmlUsage);
}
else {
  LogTools.disable();
}

window.Foundation.Gpt.InPageSlotData = [];
window.Foundation.Gpt.getInPageSlotDataById = (id) => {
  let resultSlotData = null;
  window.Foundation.Gpt.InPageSlotData.every((slotData) => {
    if (typeof slotData.id === 'undefined') return true;

    if (slotData.id === id) {
      resultSlotData = slotData;
      return false;
    }
    return true;
  });
  return resultSlotData;
}

window.Foundation.Gpt.addToInPageSlotData = (slotData) => {
  if (window.Foundation.Gpt.getInPageSlotDataById(slotData.id) !== null) {
    LogTools.warn(`Duplicate slot ${slotData.id} already in InPageSlotData.`);
  }
  else {
    window.Foundation.Gpt.InPageSlotData.push(slotData);
  }
}

// Pull from Define slots step and use this function there
window.Foundation.Gpt.DefineSlot = function (slotData) {
  LogTools.log(`Defining slot ${slotData.id}`);

  let currentSlot = null;
  if (slotData.type === 'outofpage') {
    currentSlot = window.googletag.defineOutOfPageSlot(slotData.adUnit, slotData.id);
  }
  // Special outofpage handling for anchor ads
  // https://support.google.com/admanager/answer/10452255
  else if (slotData.type === 'topanchor') {
    currentSlot = window.googletag.defineOutOfPageSlot(slotData.adUnit, window.googletag.enums.OutOfPageFormat.TOP_ANCHOR);
    currentSlot.inPageId = slotData.id; // Appended to slot for cross reference to InPageSlotData
  }
  else if (slotData.type === 'bottomanchor') {
    currentSlot = window.googletag.defineOutOfPageSlot(slotData.adUnit, window.googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR);
    currentSlot.inPageId = slotData.id; // Appended to slot for cross reference to InPageSlotData
  }
  else {
    // window.googletag.defineSlot(adUnit, sizes, divId);
    currentSlot = window.googletag.defineSlot(slotData.adUnit, slotData.sizes, slotData.id);
  }

  if (slotData.sizeMap) {
    if (slotData.sizeMap.length && typeof slotData.sizeMap[0].minSize !== 'undefined') {
      const gptSizeMapping = googletag.sizeMapping()
      slotData.sizeMap.forEach((sizeMapItem, index) => {
        LogTools.info(`Add size map [${index}] to ${slotData.id}, ${sizeMapItem.minSize}`, sizeMapItem.sizes);
        gptSizeMapping.addSize(sizeMapItem.minSize, sizeMapItem.sizes)
      });
      const gptSizeMapBuild = gptSizeMapping.build();
      currentSlot.defineSizeMapping(gptSizeMapBuild);
      LogTools.info(`Defined size mapping for ${slotData.id}`, gptSizeMapBuild);
    }
  }

  if (slotData.collapseEmptyDiv) {
    currentSlot.setCollapseEmptyDiv(true);
    LogTools.info(`Slot setCollapseEmptyDiv(true) for ${slotData.id}`);
  }

  if (slotData.targeting) {
    slotData.targeting.forEach(function (target, index) {
      currentSlot.setTargeting(target.key, target.value)
    })
  }
  currentSlot.addService(googletag.pubads());
}

window.Foundation.Gpt.SetUpPredeterminedSlot = function (element) {
  const slotDivId = element.getAttribute(gptSlotDivIdAttr);
  // slotDivId is the slotDataId for pretetermined slots
  const slotData = slotDataPresets.getDataById(slotDivId);
  if (document.querySelector(`div[${gptSlotDivIdAttr}="${slotDivId}"][${gptSlotReadyAttr}]`) !== null) {
    element.setAttribute(gptSlotReadyAttr, 'inactive: duplicate slot');
    LogTools.warn(`Duplicate slot ${slotDivId} already set up.`);
    return;
  }
  else if (window.Foundation.Gpt.Options.defineInHeaderSlotIdList.indexOf(slotDivId) < 0) {
    element.setAttribute(gptSlotReadyAttr, 'inactive: not in defineInHeaderSlotIdList');
    LogTools.warn(`Slot div id ${slotDivId} not in Foundation.Gpt.Options.defineInHeaderSlotIdList.`);
    return;
  }
  else {
    element.id = slotDivId;
    element.setAttribute(gptSlotReadyAttr, true);
  }
}

window.Foundation.Gpt.SetUpDynamicSlot = function (element) {
  const slotDivIdBase = element.getAttribute(gptSlotDivIdBaseAttr);
  const slotDataId = element.getAttribute(gptSlotConfigIdAttr);
  const slotData = slotDataPresets.getDataById(slotDataId);

  if (element.getAttribute(gptSlotReadyAttr) !== null) {
    LogTools.warn(`Already set up slot #${element.id}.`);
    return;
  }
  else if (!slotData) {
    element.setAttribute(gptSlotReadyAttr, 'inactive: slotData not defined.');
    LogTools.warn(`Slot Config ${slotDataId} not in Foundation.Gpt.SlotDataPresets.`);
    return;
  }
  else {
    const maxMultiple = 300;
    for (let i = 1; i <= maxMultiple; i++) {
      const newSlotId = `${slotDivIdBase}-${i}`;
      const newSlotIdUseable = document.querySelector(`[${gptSlotDivIdAttr}="${newSlotId}"]`) === null;
      if (newSlotIdUseable) {
        element.id = newSlotId;
        element.setAttribute(gptSlotDivIdAttr, newSlotId);
        element.setAttribute(gptSlotReadyAttr, true);

        const newSlotIdNotDefinedInGpt = window.googletag.pubads().getSlots().every((gptslot) => {
          if (gptslot.getSlotElementId() !== newSlotId) {
            return true; // Seems contrary, but every only continues if true
          } 
        });

        if (newSlotIdNotDefinedInGpt) {
          const slotDataCopy = {
            ...slotData
          }
          slotDataCopy.id = newSlotId;

          // slotDivIdBase differs from predefined slotDataId (allows for custom slotDivId)
          if (slotDataPresets.getDataById(newSlotId) === null) {
            // slotDataPresets.addData(slotDataCopy);
            window.Foundation.Gpt.addToInPageSlotData(slotDataCopy);
          }
          // Queue the slot definition
          window.googletag.cmd.push(function() {
            window.Foundation.Gpt.DefineSlot(slotDataCopy);
          });
        }
        i = maxMultiple; // End the for loop early
      }
      else if (i === maxMultiple) {
        // Warning
        LogTools.warn(`Could not generate suitable slot id for ${slotDivIdBase}.`);
      }
    }
  }
}

window.Foundation.Gpt.SetUpInlineSlot = function (element) {
  const slotDataId = element.getAttribute(gptSlotConfigIdAttr);
  const slotData = slotDataPresets.getDataById(slotDataId);

  if (element.getAttribute(gptSlotReadyAttr) !== null) {
    LogTools.warn(`Already set up slot #${element.id}.`);
    return;
  }
  else if (!slotData) {
    element.setAttribute(gptSlotReadyAttr, 'inactive: slotData not defined.');
    LogTools.warn(`Slot Config ${slotDataId} not in Foundation.Gpt.SlotDataPresets.`);
    return;
  }
  else {
    const maxMultiple = 300;
    for (let i = 1; i <= maxMultiple; i++) {
      const newSlotId = `${slotDataId}-inline-${i}`;
      const newSlotIdUseable = document.querySelector(`[${gptSlotDivIdAttr}="${newSlotId}"]`) === null;
      const newSlotIdNotDefinedInGpt = window.googletag.pubads().getSlots().every((gptslot) => {
        if (gptslot.getSlotElementId() !== newSlotId) {
          return true; // Seems contrary, but every only continues if true
        } 
      });

      LogTools.info(`SetUpInlineSlot -- newSlotId: ${newSlotId}, newSlotIdUseable: ${newSlotIdUseable}, newSlotIdNotDefinedInGpt: ${newSlotIdNotDefinedInGpt}`);
      if (newSlotIdUseable && newSlotIdNotDefinedInGpt) {
        element.id = newSlotId;
        element.setAttribute(gptSlotDivIdAttr, newSlotId);
        element.setAttribute(gptSlotReadyAttr, true);
        const slotDataCopy = {
          ...slotData
        }
        slotDataCopy.id = newSlotId;
        // slotDataPresets.addData(slotDataCopy);
        window.Foundation.Gpt.addToInPageSlotData(slotDataCopy);

        // Queue the slot definition
        window.googletag.cmd.push(function() {
          window.Foundation.Gpt.DefineSlot(slotDataCopy);
        });
        i = maxMultiple; // End the for loop early
      }
      else if (i === maxMultiple) {
        // Warning
        LogTools.warn(`Could not generate suitable slot id for ${slotDataId}.`);
      }
    }
  }
}

window.Foundation.Gpt.GetUnreadySlotElements = function () {
  const slotSelectors = [
    `div[${gptSlotDivIdAttr}]:not([${gptSlotReadyAttr}])`,
    `div[${gptSlotDivIdBaseAttr}]:not([${gptSlotReadyAttr}])`,
    `div[${gptSlotConfigIdAttr}]:not([${gptSlotReadyAttr}])`
  ]
  return document.querySelectorAll(`${slotSelectors.join(', ')}`);
}

window.Foundation.Gpt.ReadyUpSlotElements = function () {
  window.Foundation.Gpt.GetUnreadySlotElements().forEach((element) => {
    if (element.getAttribute(gptSlotReadyAttr) === null) {
      const elementSlotDivId = element.getAttribute(gptSlotDivIdAttr) || null;
      const elementSlotDivIdBase = element.getAttribute(gptSlotDivIdBaseAttr) || null;
      const elementSlotConfigId = element.getAttribute(gptSlotConfigIdAttr) || null;

      if (elementSlotDivId) {
        window.Foundation.Gpt.SetUpPredeterminedSlot(element);
      }
      else if (elementSlotDivIdBase && elementSlotConfigId) {
        window.Foundation.Gpt.SetUpDynamicSlot(element);
      }
      else if (elementSlotConfigId) {
        window.Foundation.Gpt.SetUpInlineSlot(element);
      }
    }
  });
}

window.Foundation.Gpt.SearchForAdsToDisplay = () => {
  window.googletag.pubads().getSlots().forEach((gptSlot) => { 
    const slotElementId = gptSlot.getSlotElementId();
    const slotElement = document.querySelector(`div[id="${slotElementId}"][${gptSlotReadyAttr}]`);
    if (slotElement !== null) {
      const slotElementReady = slotElement.getAttribute(gptSlotReadyAttr) !== null;
      const slotNotDisplayed = slotElement.getAttribute(gptSlotDisplayedAttr) === null;
      if (slotElementReady && slotNotDisplayed) {
        slotElement.setAttribute(gptSlotDisplayedAttr, true);
        if (window.Foundation.Gpt.Options.showPlaceholders) {
          if (slotElementId === 'FixedFooter') {
            ShowAd.placeholderFixedFooter(gptSlot, window.Foundation.Gpt.getInPageSlotDataById(slotElementId));
          }
          else {
            ShowAd.placeholder(gptSlot, window.Foundation.Gpt.getInPageSlotDataById(slotElementId));
          }
        }
        else {
          ShowAd.display(gptSlot);
        }
      }
    }
  });
}

window.Foundation.Gpt.ReportAdElementsNotFound = function () {
  window.googletag.pubads().getSlots().forEach((slot) => { 
    const slotElementId = slot.getSlotElementId();
    if (slotElementId !== 'FixedFooter' && !slot.getOutOfPage()) {
      if (document.querySelector(`div[${gptSlotDivIdAttr}="${slotElementId}"]`) === null) {
        LogTools.info(`Ad Element [id="${slotElementId}"] not found in page.`);
      }
    }
  });
}

window.Foundation.Gpt.RefreshAll = function () {
  window.googletag.pubads().getSlots().forEach((gptSlot) => { 
    const slotElementId = gptSlot.getSlotElementId();
    const slotElement = document.querySelector(`div[id="${slotElementId}"][${gptSlotReadyAttr}]`);

    // Known refreshable outofpage gptSlots (topanchor|bottomanchor) have inPageId property appended 
    if (gptSlot.getOutOfPage() && window.Foundation.Gpt.getInPageSlotDataById(gptSlot.inPageId)) {
      ShowAd.refresh(gptSlot);
    }
    else if (slotElement !== null) {
      const slotElementReady = slotElement.getAttribute(gptSlotReadyAttr) !== null;
      const slotAlreadyDisplayed = slotElement.getAttribute(gptSlotDisplayedAttr) !== null;
      if (slotElementReady && slotAlreadyDisplayed) {
        const slotData = window.Foundation.Gpt.getInPageSlotDataById(slotElementId);
        const slotType = slotData.type;
        if (slotType !== 'outofpage') {
          if (window.Foundation.Gpt.Options.showPlaceholders) {
            if (slotElementId === 'FixedFooter') {
              ShowAd.reloadPlaceholderFixedFooter(gptSlot, slotData);
            }
            else {
              ShowAd.reloadPlaceholder(gptSlot, slotData);
            }
          }
          else {
            if (slotElementId === 'FixedFooter') {
              ShowAd.refreshFixedFooter(gptSlot);
            }
            else {
              ShowAd.refresh(gptSlot);
            }
          }
        }
      }
    }
  });
}

// Populate slot data --------------------------------------------------------------------
window.Foundation.Gpt.Options.defineInHeaderSlotIdList.forEach((slotDataId) => {
  // Try to determine base slot config name and create a copy
  const baseSlotDataId = slotDataId.replace(/-\w*$/,'');
  const baseSlotData = slotDataPresets.getDataById(baseSlotDataId);
  if (baseSlotData) {
    const slotDataCopy = {
      ...baseSlotData
    }
    slotDataCopy.id = slotDataId;
    // slotDataPresets.addData(slotDataCopy);
    window.Foundation.Gpt.addToInPageSlotData(slotDataCopy);

    // slotData = slotDataPresets.getDataById(slotDataId);
  }
  else {
    // Warning
    LogTools.warn(`Could not locate base slotData for slot ${slotDataId}`);
  }
});

// ---------------------------------------------------------------------------------------
// --------------------------- Google Publisher Tag Header -------------------------------
// ---------------------------------------------------------------------------------------
window.googletag = window.googletag || {};
window.googletag.cmd = window.googletag.cmd || [];
window.googletag.cmd.push(function() {

  // Define slots ------------------------------------------------------------------------
  window.Foundation.Gpt.InPageSlotData.forEach((slotData) => {
    window.Foundation.Gpt.DefineSlot(slotData);
  });

  // Page level targeting ----------------------------------------------------------------
  for (let item of window.Foundation.Gpt.Options.pageLeveTargeting) {
    LogTools.log(`Defining page level targeting: ${item.key} = ${item.value}`);
    window.googletag.pubads().setTargeting(item.key, item.value);
  }

  // GPT event handling ------------------------------------------------------------------
  window.googletag.pubads().addEventListener('slotRequested', function(event) {
    const slotElementId = event.slot.getSlotElementId();
    LogTools.info(`GPT event ${slotElementId} slotRequested`);
  });

  window.googletag.pubads().addEventListener('slotResponseReceived', function(event) {
    const slotElementId = event.slot.getSlotElementId();
    const adElement = document.querySelector(`[id="${slotElementId}"]`);
    let adReceivedInfo = "Ad data received";
    if (event.slot.getResponseInformation() === null) {
      adReceivedInfo = "No ad data received";
      // if (adElement && adElement.id !== `FixedFooter`) {
      //   adElement.hidden = true;
      // }
    }
    LogTools.info(`GPT event ${slotElementId} slotResponseReceived ${adReceivedInfo}`);
  });

  window.googletag.pubads().addEventListener('slotOnload', function(event) {
    const slotElementId = event.slot.getSlotElementId();
    const adElement = document.querySelector(`[id="${slotElementId}"]`);
    adElement?.removeAttribute('hidden');
    LogTools.info(`GPT event ${slotElementId} slotOnload`);
  });

  window.googletag.pubads().addEventListener('impressionViewable', function(event) {
    LogTools.info(`GPT event ${event.slot.getSlotElementId()} impressionViewableEvent`);
    const slot = event.slot;
    const slotRefreshValue = slot.getTargeting(gptRefreshTimerKey);
    const slotRefreshTimeout = slotRefreshValue[0];
    if (typeof slotRefreshTimeout === 'string' && slotRefreshTimeout.match(/^\d+$/)) {
      const refreshInSeconds = parseInt(slotRefreshTimeout);
      if (refreshInSeconds < 30) {
        LogTools.warn(`GPT event ${event.slot.getSlotElementId()} refresh timer should be no less than 30 seconds.`);
      }

      // Setup refreshCount if not already set up
      if (typeof slot.refreshCount === `undefined`) {
        if (slotRefreshValue[1] && parseInt(slotRefreshValue[1]) !== NaN) {
          slot.refreshCount = parseInt(slotRefreshValue[1]);
        }
        else {
          slot.refreshCount = 100001;
        }
      }

      // Setup refresh timer if theres enoug refreshes left
      if (slot.refreshCount < 1) {
        LogTools.info(`GPT event ${event.slot.getSlotElementId()} refresh timer started for ${refreshInSeconds} seconds. RefreshesLeft ${slot.refreshCount}`);
      } else {
        LogTools.info(`GPT event ${event.slot.getSlotElementId()} refresh timer started for ${refreshInSeconds} seconds. RefreshesLeft ${slot.refreshCount}`);
        if (typeof slot.fdnGptRefreshTimeout === 'number') {
          clearTimeout(slot.fdnGptRefreshTimeout);
        }
        slot.fdnGptRefreshTimeout = setTimeout(function() {
          LogTools.info(`GPT event ${event.slot.getSlotElementId()} refreshing ad.`);
          slot.refreshCount--;
          googletag.pubads().refresh([slot]);
        }, refreshInSeconds * 1000);
      }
    }
  });

  window.googletag.pubads().addEventListener('slotRenderEnded', function(event) {
    LogTools.info(`GPT event ${event.slot.getSlotElementId()} slotRenderEndedEvent`);
  });

  window.googletag.pubads().addEventListener('slotVisibilityChanged', function(event) {
    LogTools.info(`GPT event ${event.slot.getSlotElementId()} slotVisibilityChangedEvent`);
  });

  // Standard gpt init stuff -------------------------------------------------------------
  window.googletag.pubads().enableSingleRequest();
  window.googletag.pubads().collapseEmptyDivs();
  window.googletag.enableServices();

  // Set up Out-of-page Ads --------------------------------------------------------------
  window.googletag.pubads().getSlots().forEach((gptslot) => { 
    if (gptslot.getOutOfPage && gptslot.getOutOfPage() && !window.Foundation.Gpt.Options.showPlaceholders) {
      ShowAd.displayOutOfPageAd(gptslot);
    }
  });

  // Set up the fixed Footer Ad ----------------------------------------------------------
  window.googletag.pubads().getSlots().forEach((slot) => { 
    const slotElementId = slot.getSlotElementId();
    if (slotElementId === 'FixedFooter') {
      if (window.Foundation.Gpt.Options.showPlaceholders) {
        ShowAd.placeholderFixedFooter(slot, slotDataPresets.getDataById(slotElementId));
      }
      else {
        ShowAd.displayFixedFooter(slot);
      }
    }
  });

  // Rate limited resize event for GPT manipulation --------------------------------------
  const windowResizeEvent = new Event('foundation:ad:resize'); 
  let adLastWindowWidth = window.innerWidth;
  let adResizeTimeout = null
  window.addEventListener('resize', (e) => {
    clearTimeout(adResizeTimeout);
    adResizeTimeout = setTimeout(() => {
      const resizeAboveBreakPoint = window.Foundation.Gpt.Options.layoutBreakPoints.filter((breakPoint) => {
        // LogTools.info(`${adLastWindowWidth} < ${breakPoint} && ${window.innerWidth} >= ${breakPoint}: ${adLastWindowWidth < breakPoint && window.innerWidth >= breakPoint}`);
        return adLastWindowWidth < breakPoint && window.innerWidth >= breakPoint;
      });
      const resizeBelowBreakPoint = window.Foundation.Gpt.Options.layoutBreakPoints.filter((breakPoint) => {
        // LogTools.info(`${adLastWindowWidth} >= ${breakPoint} && ${window.innerWidth} < ${breakPoint}: ${adLastWindowWidth >= breakPoint && window.innerWidth < breakPoint}`);
        return adLastWindowWidth >= breakPoint && window.innerWidth < breakPoint;
      });

      if (resizeAboveBreakPoint.length || resizeBelowBreakPoint.length) {
        adLastWindowWidth = window.innerWidth;
        LogTools.info('Event foundation:ad:resize triggered.');
        window.dispatchEvent(windowResizeEvent);
      }
    }, 200);
  });
});

// -------------------------------------------------------------------------------------
// Show ads as they flow into the page

// Initial run as soon as GPT is ready
window.googletag.cmd.push(() => {
  // Offset Search for ads to display by a short duration so it occurs after ready up.
  window.Foundation.Gpt.ReadyUpSlotElements();
  setTimeout(window.Foundation.Gpt.SearchForAdsToDisplay, 100);
});

// Periodically run as soon as GPT is ready
window.googletag.cmd.push(() => {
  setTimeout(() => {
    setInterval(() => { 
      window.Foundation.Gpt.ReadyUpSlotElements();
      window.Foundation.Gpt.SearchForAdsToDisplay();
    }, 500);
  }, 200);
});

// -------------------------------------------------------------------------------------
// Report missing slots after duration
window.googletag.cmd.push(() => {
  setTimeout(window.Foundation.Gpt.ReportAdElementsNotFound, 10000);
});

// -------------------------------------------------------------------------------------


// -------------------------------------------------------------------------------------
// Expose for use in the page
window.Foundation.Gpt.LogTools = LogTools;
window.Foundation.Gpt.ShowAd = ShowAd;
