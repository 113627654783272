import LogTools       from '../LogTools.js';
import { gptSlotDivIdAttr, gptSlotDivIdBaseAttr, gptSlotConfigIdAttr, gptSlotReadyAttr } from '../fdn-gpt.js';

LogTools.enable();

export default { 
  display,
  displayOutOfPageAd,
  displayFixedFooter,
  refresh,
  refreshFixedFooter,
  placeholder,
  placeholderFixedFooter,
  reloadPlaceholder,
  reloadPlaceholderFixedFooter
};

function display (gptSlot) {
  if (!gptSlot) {
    LogTools.warn(`ShowAd.display gptSlot not supplied or no value`); 
    return;
  }

  window.googletag.cmd.push(function() {
    const slotDivId = gptSlot.getSlotElementId();
    LogTools.log(`Calling googletag.display for ${slotDivId}`);
    window.googletag.display(gptSlot);
  });
}

function refresh (gptSlot) {
  if (!gptSlot) {
    LogTools.warn(`ShowAd.refresh gptSlot not supplied or no value`); 
    return;
  }

  const slotDivId = gptSlot.getSlotElementId();
  const adElement = document.querySelector(`[id="${slotDivId}"]`);
  if (adElement) {
    window.googletag.cmd.push(function() {
      adElement.removeAttribute('hidden');
      LogTools.info(`Refreshing ad ${slotDivId}`);
      window.googletag.pubads().refresh([gptSlot]);
    });
  }
}

function displayOutOfPageAd (gptSlot) {
  if (!gptSlot) {
    LogTools.warn(`ShowAd.displayOutOfPageAd gptSlot not supplied or no value`); 
    return;
  }
  display(gptSlot);
  // buildOutOfPageAdElement(gptSlot, () => { display(gptSlot); });
}

function displayFixedFooter (gptSlot) {
  if (!gptSlot) {
    LogTools.warn(`ShowAd.displayFixedFooter gptSlot not supplied or no value`); 
    return;
  }
  buildFixedFooterContainer(gptSlot, () => { display(gptSlot); });
}

function refreshFixedFooter (gptSlot) {
  if (!gptSlot) {
    LogTools.warn(`ShowAd.refreshFixedFooter gptSlot not supplied or no value`); 
    return;
  }
  buildFixedFooterContainer(gptSlot, () => { refresh(gptSlot); });
}

function placeholderFixedFooter (gptSlot, slotConfig) {
  buildFixedFooterContainer(gptSlot, () => { placeholder(gptSlot, slotConfig); });
}

function reloadPlaceholderFixedFooter (gptSlot, slotConfig) {
  buildFixedFooterContainer(gptSlot, () => { reloadPlaceholder(gptSlot, slotConfig); });
}

function placeholder (gptSlot, slotConfig) {
  if (!gptSlot) {
    LogTools.warn(`ShowAd.placeholder gptSlot for slotDivId: ${slotConfig.id} not supplied or missing value`); 
    return;
  }

  if (!slotConfig) {
    LogTools.warn(`ShowAd.placeholder slotConfig for slotDivId: ${gptSlot.getSlotElementId()} not supplied or missing value`); 
    return;
  }

  const slotDivId = gptSlot.getSlotElementId();
  const displayWidth = window.innerWidth;
  const adElement = document.querySelector(`[${gptSlotDivIdAttr}="${slotDivId}"]`);

  if (adElement === null) {
    LogTools.warn(`ShowAd.placeholder ad element with attribute ${gptSlotDivIdAttr}="${slotDivId}" not found.`); 
    return;
  }

  // Clean out the ad container
  adElement.id = slotDivId;
  adElement.hidden = true;
  adElement.innerHTML = '';

  // Bail if out of page placement
  if (gptSlot.getOutOfPage()) {
    LogTools.info(`ShowAd.placeholder slotDivId: ${slotDivId} is Out-of-page so no placeholder to render.`); 
    return;
  }

  // Bail if there's no config for this configDivId
  if (typeof slotConfig === 'undefined' || slotConfig === null) {
    console.warn(`ShowAd.placeholder slotConfig for slotDivId: ${slotDivId} not supplied or no value.`)
    return;
  }

  // Bail if there's no sizes in config for this configDivId
  else if (typeof slotConfig.sizes === 'undefined' || slotConfig.sizes === null) {
    console.warn(`ShowAd.placeholder slotConfig for slotDivId: ${slotDivId} does not contain sizes.`)
    return;
  }

  // Gather ad slot details
  let currentSize;

  let currentSizeText = ``;
  let allSizesText = ``;
  let sizeMapHtml = ``;

  const parseSizes = (size) => {
    if (size.length === 2 && typeof size[0] === 'number') {
      return `${size[0]}x${size[1]}`;
    }
    if (typeof size[0] === 'string') {
      return size;
    }
    return false;
  }

  const allSizes = [];
  const firstSizeIsString = typeof slotConfig.sizes[0] === 'string';
  const secondSizeIsObject = typeof slotConfig.sizes[1] === 'object';
  const isNestedBeginsWithString = firstSizeIsString && secondSizeIsObject;
  const isNestedDimensions = slotConfig.sizes && slotConfig.sizes[0] && typeof slotConfig.sizes[0][0] === 'number';

  if (slotConfig.sizes.length === 0) {
    allSizes.push(`No sizes defined`)
  }
  else if (isNestedDimensions || isNestedBeginsWithString) {
    slotConfig.sizes.forEach((sizesItem, sizeIndex) => {
      allSizes.push(parseSizes(sizesItem) || `Unexpected sizes item config`);
    });
  }
  else if (typeof slotConfig.sizes[0] === 'number') {
    allSizes.push(parseSizes(slotConfig.sizes) || `Unexpected sizes item config`);
  }
  else if (typeof slotConfig.sizes[0] === 'string') {
    allSizes.push(parseSizes(slotConfig.sizes) || `Unexpected sizes item config`);
  }
  else {
    allSizes.push(`Unexpected sizes list config`)
  }

  allSizesText = allSizes.join(`, `);

  const sizeMap = slotConfig.sizeMap || {};
  sizeMap.forEach((sizeMapItem, sizeMapIndex) => {
    const breakPoint = sizeMapItem.minSize[0];
    const breakPointSizes = [];

    const firstSizeIsString = typeof sizeMapItem.sizes[0] === 'string';
    const secondSizeIsObject = typeof sizeMapItem.sizes[1] === 'object';
    const isNestedBeginsWithString = firstSizeIsString && secondSizeIsObject;
    const isNestedDimensions = sizeMapItem.sizes && sizeMapItem.sizes[0] && typeof sizeMapItem.sizes[0][0] === 'number';

    if (sizeMapItem.sizes.length === 0) {
      breakPointSizes.push(`No sizes for this breakpoint`)
    }
    else if (isNestedDimensions || isNestedBeginsWithString) {
      sizeMapItem.sizes.forEach((sizesItem, sizeIndex) => {
        breakPointSizes.push(parseSizes(sizesItem) || `Unexpected sizeMap.sizes item config`);
      });
    }
    else if (typeof sizeMapItem.sizes[0] === 'number') {
      breakPointSizes.push(parseSizes(sizeMapItem.sizes) || `Unexpected sizeMap.sizes item config`);
    }
    else if (typeof sizeMapItem.sizes[0] === 'string') {
      breakPointSizes.push(parseSizes(sizeMapItem.sizes) || `Unexpected sizeMap.sizes item config`);
    }
    else {
      breakPointSizes.push(`Unexpected sizeMap.sizes list config`)
    }

    if (!currentSize && displayWidth > breakPoint) {
      currentSizeText = breakPointSizes[0];
      if (isNestedDimensions || isNestedBeginsWithString) {
        currentSize = sizeMapItem.sizes[0];
      }
      else {
        currentSize = sizeMapItem.sizes;
      }
    }

    sizeMapHtml += `<b>Sizes for browser width over ${breakPoint}px:</b><br>\n`;
    sizeMapHtml += `${breakPointSizes.join(`, `)}<br>\n`;
  });

  // Targeting info
  let targetingHtml = '';
  const targetingKeys = gptSlot.getTargetingKeys();
  if (targetingKeys.length) {
    targetingHtml += `<br><b>Slot targeting:</b><br>`;
    targetingKeys.forEach((targetKey, index) => {
      targetingHtml += `${targetKey} = ${gptSlot.getTargeting(targetKey)} <br>`;
    });
  }

  targetingHtml += `<br>`;

  targetingHtml += `<b>Page level targeting:</b><br>`;
  window.googletag.pubads().getTargetingKeys().forEach((targetItem, index) => {
    targetingHtml += `${targetItem} = ${window.googletag.pubads().getTargeting(targetItem)} <br>`;
  });

  // Build placeholder HTML and place child element
  adElement.innerHTML = `\
    <div class="fdn-gpt-placeholder-content" style="box-sizing: border-box;width: 100%;max-width: 100%;">\
      <b>Slot &lt;div&gt; ID:</b><br>\
      ${slotDivId}<br>\
      <b>Ad Unit:</b><br>\
      ${slotConfig.adUnit}<br>\
      <b>Current size:</b> ${currentSizeText}<br>\
      <b>All Sizes:</b><br>\
      ${allSizesText}<br>\
      ${sizeMapHtml}\
      ${targetingHtml}\
    </div>\
  `;

  const adElementChild = adElement.querySelector('div');
  adElementChild.style.boxSizing =       `border-box`;
  adElementChild.style.color =           `#000000`;
  adElementChild.style.backgroundColor = `#eeeeee`;
  adElementChild.style.border =          `solid 1px gray`;
  adElementChild.style.padding =         `15px`;
  adElementChild.style.marginLeft =      `auto`;
  adElementChild.style.marginRight =     `auto`;
  adElementChild.style.overflow =        `auto`;
  adElementChild.style.fontFamily =      `sans-serif`;
  adElementChild.style.fontSize =        `12px`;

  if (currentSize === 'fluid') {
    const containerWidth = adElement.parentElement.getBoundingClientRect().width;
    adElementChild.style.width =           `${containerWidth}px`;
    adElementChild.style.height =          `250px`;
  }
  else {
    adElementChild.style.width =           `${currentSize[0]}px`;
    adElementChild.style.height =          `${currentSize[1]}px`;
  }

  adElement.removeAttribute('hidden');
}

function reloadPlaceholder (gptSlot, slotConfig) {
  const slotDivId = gptSlot.getSlotElementId();
  const displayWidth = window.innerWidth;
  const adElement = document.querySelector(`[${gptSlotDivIdAttr}="${slotDivId}"]`);
  adElement.removeAttribute('id');
  placeholder(gptSlot, slotConfig);
}

function buildOutOfPageAdElement (gptSlot, callback) {
  if (!gptSlot) {
    LogTools.warn(`ShowAd.buildOutOfPageContainer gptSlot not supplied or no value`); 
    return;
  }

  const slotDivId = gptSlot.getSlotElementId();
  let adElement = document.querySelector(`[${gptSlotDivIdAttr}="${slotDivId}"]`);
  const bodyElement = document.querySelector('body');

  if (!bodyElement) return;

  // Create Ad Element if it doesn't already exist
  if (!adElement) {
    adElement = document.createElement('div');
    adElement.id = slotDivId;
    adElement.setAttribute(gptSlotDivIdAttr, slotDivId);
    adElement.style.width = '0';
    adElement.style.height = '0';
    bodyElement.append(adElement);
  }

  if (typeof callback === 'function') {
    callback();
  }
}

function buildFixedFooterContainer (gptSlot, callback) {
  if (!gptSlot) {
    LogTools.warn(`ShowAd.buildFixedFooterContainer gptSlot not supplied or no value`); 
    return;
  }

  const slotDivId = gptSlot.getSlotElementId();
  let adElement = document.querySelector(`[${gptSlotDivIdAttr}="${slotDivId}"]`);

  const adContainerId = slotDivId + '-container';
  const adContainerPadding = (window.innerWidth < Foundation.Gpt.desktopBreakpoint)? 1 : 2;

  const adSpacerId = adContainerId + '-spacer';

  const closeIconCornerTop = 2;
  const closeIconCornerRight = 2;
  const closeIconButtonSize = 14;

  const closeIconPadding = 2; // Integer
  const closeIconInnerPadding = 2; // Integer
  const closeIconSize = closeIconButtonSize - closeIconPadding;
  const closeIconInnerSize = closeIconSize - closeIconInnerPadding;

  const bodyElement = document.querySelector('body');
  if (!bodyElement) return;

  // Create Ad Element if it doesn't already exist
  if (!adElement) {
    adElement = document.createElement('div');
    adElement.id = slotDivId;
    adElement.setAttribute(gptSlotDivIdAttr, slotDivId);
  }

  // Create Ad Container Element if it doesn't already exist
  let adContainerElement = document.querySelector(`[id="${adContainerId}"]`);
  if (!adContainerElement) {
    adContainerElement = document.createElement('div');
    adContainerElement.id = adContainerId;

    const adContainerStyleElement = document.createElement('style');
    adContainerStyleElement.innerHTML = `\
      #${adContainerId} {\
        background-color: #e2e2e3;\
        border-top: solid 1px #d2d2d3;\
        bottom: 0;\
        box-sizing: border-box;\
        display: flex;\
        justify-content: space-around;\
        padding: ${adContainerPadding}px 0;\
        position: fixed;\
        text-align: center;\
        width: 100%;\
        max-height: 270px;\
        z-index: 1000;\
        transition: max-height 0.5s;\
      }\
      #${adContainerId}.slideDown {\
        max-height: 0;\
      }\
    `;

    adContainerElement.append(adContainerStyleElement);
    adContainerElement.append(adElement);
    bodyElement.append(adContainerElement);
  }

  // Create Close Button Element if it doesn't already exist
  let closeButtElement = document.querySelector(`[id="${adContainerId}"]`).querySelector('.closeButt');
  if (!closeButtElement) {
    const closeButtIconElement = document.createElement('svg')
    closeButtIconElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    closeButtIconElement.setAttribute('width', closeIconSize);
    closeButtIconElement.setAttribute('height', closeIconSize);
    closeButtIconElement.setAttribute('viewBox', `0 0 ${closeIconSize} ${closeIconSize}`);
    closeButtIconElement.innerHTML = `<path fill="none" stroke="#000" stroke-width="3"\
      d="M${closeIconInnerSize},${closeIconInnerSize} L${closeIconInnerPadding},${closeIconInnerPadding}\
      M${closeIconInnerSize},${closeIconInnerPadding} L${closeIconInnerPadding},${closeIconInnerSize}"></path>\
    `;

    const closeButtStyleElement = document.createElement('style');
    closeButtStyleElement.innerHTML = `\
      #${adContainerId} .closeButt svg path {\
        stroke: rgba(0,0,0,0.25);\
      }\
      #${adContainerId} .closeButt:hover {\
        background-color: rgba(255,255,255,1);\
        color: rgba(0,0,0,1);\
        border-color: rgba(0,0,0,1);\
      }\
      #${adContainerId} .closeButt:hover svg path {\
        stroke: rgba(0,0,0,1);\
      }\
    `;

    closeButtElement = document.createElement('div');
    closeButtElement.innerText = '✖';
    closeButtElement.classList.add('closeButt');
    closeButtElement.style.position         = 'absolute';
    closeButtElement.style.top              = `${closeIconCornerTop}px`;
    closeButtElement.style.right            = `${closeIconCornerRight}px`;
    closeButtElement.style.fontWeight       = '300';
    closeButtElement.style.fontFamily       = 'Arial, sans-serif';
    closeButtElement.style.fontSize         = `${closeIconButtonSize}px`;
    closeButtElement.style.lineHeight       = `${closeIconButtonSize}px`;
    closeButtElement.style.width            = `${closeIconButtonSize}px`;
    closeButtElement.style.height           = `${closeIconButtonSize}px`;
    closeButtElement.style.transition       = 'height 0.5s';
    closeButtElement.style.borderRadius     = `calc(${closeIconButtonSize} / 2)`;
    closeButtElement.style.display          = 'flex';
    closeButtElement.style.justifyContent   = 'space-around';
    closeButtElement.style.alignItems       = 'center';
    closeButtElement.style.backgroundColor  = 'rgba(255,255,255,0.33)';
    closeButtElement.style.color            = 'rgba(0,0,0,0.33)';
    closeButtElement.style.border           = 'solid 1px rgba(0,0,0,0.33)';

    closeButtElement.append(closeButtIconElement);
    closeButtElement.append(closeButtStyleElement);
    adContainerElement.append(closeButtElement);

    const closeButtClick = function (e) {
      e.preventDefault();
      closeButtElement.removeEventListener('click', closeButtClick);
      adContainerElement.classList.add('slideDown');
      bodyElement.classList.remove('fdnFixedFooterAdSpacing');

      setTimeout(() => { 
        adContainerElement.remove() 
      }, 500);
    }

    // Setup close button
    closeButtElement.addEventListener('click', closeButtClick)
  }

  // Create Ad Spacing if it doesn't already exist
  let spacerStyleElement = document.querySelector(`style[id="${adSpacerId}"]`);
  if (!spacerStyleElement) {
    const bodyElementMarginBottom = bodyElement.style.marginBottom;
    spacerStyleElement = document.createElement('style');
    spacerStyleElement.id = adSpacerId;
    spacerStyleElement.innerText = `\
      body {\
        margin-bottom: ${bodyElementMarginBottom};\
        transition: margin-bottom 0.5s;\
      }\
      body.fdnFixedFooterAdSpacing {\
        margin-bottom: 100px;\
      }\
    `;
    bodyElement.classList.add('fdnFixedFooterAdSpacing')
    adContainerElement.append(spacerStyleElement);
  }

  if (typeof callback === 'function') {
    callback();
  }
}